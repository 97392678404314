var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticStyle: { color: "darkred" } }, [
        _vm._v("Program selection"),
      ]),
      _c("br"),
      _c("h3", [
        _vm._v(
          "Click on your agency or select manually the programs to assess"
        ),
      ]),
      _c("br"),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5 grey lighten-2" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _c("v-card-text", [_vm._v(_vm._s(_vm.description))]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.showDialog = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { key: _vm.updateCheckBox, attrs: { md: "12" } },
            _vm._l(_vm.agencies, function (agency) {
              return _c(
                "v-btn",
                {
                  key: agency.id,
                  staticClass: "ma-2",
                  attrs: {
                    color: _vm.isAgencySelected(agency)
                      ? "red lighten-5"
                      : "blue-grey lighten-4",
                    small: "",
                    elevation: "3",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.selectProgramsForAgency(agency)
                    },
                  },
                },
                [_vm._v(_vm._s(agency.name) + " ")]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c(
        "v-row",
        { staticStyle: { margin: "1px" } },
        [
          _c("v-col", { attrs: { md: "3" } }),
          _c("v-col", { attrs: { md: "5" } }),
          _c("v-col", { attrs: { md: "3" } }),
          _c(
            "v-col",
            { attrs: { md: "1" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    to: "Questionnaire",
                    text: "",
                    color: "primary",
                    disabled: this.$store.state.programSelection.size === 0,
                  },
                },
                [_vm._v("Start "), _c("v-icon", [_vm._v("mdi-step-forward")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._l(this.$store.state.areas, function (area) {
        return _c(
          "v-card",
          {
            key: area.id,
            attrs: { elevation: 2, color: "blue-grey lighten-4" },
          },
          [
            _c(
              "v-container",
              { staticClass: "fill-height", attrs: { fluid: "" } },
              _vm._l(area.groups, function (group, index) {
                return _c(
                  "v-row",
                  { key: group.id, attrs: { "no-gutters": "" } },
                  [
                    _c("v-col", { attrs: { md: "2" } }, [
                      index === 0
                        ? _c("h3", [_vm._v(_vm._s(area.name))])
                        : _vm._e(),
                    ]),
                    _c(
                      "v-col",
                      { attrs: { md: "4" } },
                      [
                        _c(
                          "v-list-item",
                          {
                            staticStyle: { "font-size": "1px" },
                            attrs: { dense: "" },
                            on: {
                              click: function ($event) {
                                return _vm.showGroup(group.id)
                              },
                            },
                          },
                          [
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(group.name)),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-icon",
                              [
                                _vm.isAnyProgramSelected(group)
                                  ? _c(
                                      "v-icon",
                                      { attrs: { color: "blue darken-2" } },
                                      [_vm._v("mdi-checkbox-marked")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-icon",
                              [
                                !_vm.isGroupSelected(group.id)
                                  ? _c("v-icon", [_vm._v("mdi-plus")])
                                  : _vm._e(),
                                _vm.isGroupSelected(group.id)
                                  ? _c("v-icon", [_vm._v("mdi-minus")])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { md: "6" } },
                      [
                        _vm.isGroupSelected(group.id)
                          ? _c(
                              "v-list-item-group",
                              _vm._l(group.programs, function (program) {
                                return _c(
                                  "v-list-item",
                                  { key: program.id, attrs: { dense: "" } },
                                  [
                                    _c(
                                      "v-list-item-action",
                                      [
                                        _c("v-checkbox", {
                                          key: _vm.updateCheckBox,
                                          attrs: {
                                            "input-value": _vm.getCheckBoxValue(
                                              program.id
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.selectProgram(
                                                program.id
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          color: "black",
                                          "x-small": "",
                                          text: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showProgramDescription(
                                              program
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(program.name) + " ")]
                                    ),
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }