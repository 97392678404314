var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticStyle: { color: "darkred" } }, [
        _vm._v("Answers report"),
      ]),
      _vm.report === undefined
        ? _c("h3", [
            _vm._v(
              "Click on the button to generate the report with answers and questions"
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "text-left" },
        [
          _vm.report === undefined
            ? _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { color: "primary" },
                  on: { click: _vm.loadAnswers },
                },
                [_vm._v("create report")]
              )
            : _vm._e(),
          _vm.report !== undefined
            ? _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: { click: _vm.printDownload },
                },
                [_vm._v("print")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("br"),
      _vm.report !== undefined
        ? _c("report", {
            ref: "report",
            attrs: { report: _vm.report, questions: _vm.questions },
          })
        : _vm._e(),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }