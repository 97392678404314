var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("br"),
      _c("h4", [
        _vm._v(
          "What is the level of development for " +
            _vm._s(_vm.title) +
            " in your country? "
        ),
      ]),
      _c("br"),
      _vm._l(_vm.dimensions, function (dimension, index) {
        return _c(
          "v-row",
          { key: dimension.title },
          [
            _vm.dimensions.length > 1
              ? _c("v-col", { attrs: { md: "12" } }, [
                  _c("h4", [_vm._v(_vm._s(dimension.title))]),
                ])
              : _vm._e(),
            _c(
              "v-col",
              { attrs: { md: "3" } },
              [
                _c("v-hover", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var hover = ref.hover
                          return [
                            _c(
                              "v-card",
                              {
                                key: _vm.update,
                                attrs: {
                                  height: "200px",
                                  elevation: hover ? 16 : 2,
                                  color: _vm.isSelected(index, "NASCENT"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.select("NASCENT", index)
                                  },
                                },
                              },
                              [
                                _c("v-card-title", [_vm._v("Nascent")]),
                                _c("v-card-text", [
                                  _vm._v(_vm._s(dimension.nascent_description)),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { md: "3" } },
              [
                _c("v-hover", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var hover = ref.hover
                          return [
                            _c(
                              "v-card",
                              {
                                key: _vm.update,
                                attrs: {
                                  height: "200px",
                                  elevation: hover ? 16 : 2,
                                  color: _vm.isSelected(index, "ENGAGED"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.select("ENGAGED", index)
                                  },
                                },
                              },
                              [
                                _c("v-card-title", [_vm._v("Engaged")]),
                                _c("v-card-text", [
                                  _vm._v(_vm._s(dimension.engaged_description)),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { md: "3" } },
              [
                _c("v-hover", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var hover = ref.hover
                          return [
                            _c(
                              "v-card",
                              {
                                key: _vm.update,
                                attrs: {
                                  height: "200px",
                                  elevation: hover ? 16 : 2,
                                  color: _vm.isSelected(index, "CAPABLE"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.select("CAPABLE", index)
                                  },
                                },
                              },
                              [
                                _c("v-card-title", [_vm._v("Capable")]),
                                _c("v-card-text", [
                                  _vm._v(_vm._s(dimension.capable_description)),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { md: "3" } },
              [
                _c("v-hover", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var hover = ref.hover
                          return [
                            _c(
                              "v-card",
                              {
                                key: _vm.update,
                                attrs: {
                                  height: "200px",
                                  elevation: hover ? 16 : 2,
                                  color: _vm.isSelected(index, "EFFECTIVE"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.select("EFFECTIVE", index)
                                  },
                                },
                              },
                              [
                                _c("v-card-title", [_vm._v("Effective")]),
                                _c("v-card-text", [
                                  _vm._v(
                                    _vm._s(dimension.effective_description)
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c("br"),
      _c("h3", [_vm._v("Please justify your answer")]),
      _c("v-textarea", {
        attrs: { rows: "5", outlined: "" },
        model: {
          value: _vm.displayedJustification,
          callback: function ($$v) {
            _vm.displayedJustification = $$v
          },
          expression: "displayedJustification",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }