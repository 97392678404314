var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "4" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("fromKeyAgencyToNationalFramework")
                    },
                  },
                },
                [
                  _c("v-icon", [_vm._v("mdi-step-backward")]),
                  _vm._v(_vm._s(this.previousStepMessage())),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-col", [
            _c("h3", { staticStyle: { color: "darkred" } }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
          ]),
          _c(
            "v-col",
            { staticClass: "text-right", attrs: { md: "4" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("fromKeyAgencyToEvolution")
                    },
                  },
                },
                [
                  _vm._v(_vm._s(this.nextStepMessage())),
                  _c("v-icon", [_vm._v("mdi-step-forward")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("h5", [
        _vm._v(
          _vm._s(_vm.page) +
            " of " +
            _vm._s(this.questions.length) +
            " questions"
        ),
      ]),
      _c("h3", { staticStyle: { color: "darkred" } }, [_vm._v("Description")]),
      _c("v-textarea", {
        attrs: {
          rows: "6",
          value: _vm.displayDescription,
          readonly: "",
          outlined: "",
          dense: "",
        },
      }),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { md: "12" } },
            [
              _c("h3", { staticStyle: { color: "darkred" } }, [
                _vm._v(
                  "Please reflect how the local situation relates to the following statement"
                ),
              ]),
              _c("v-textarea", {
                attrs: {
                  rows: "2",
                  value: _vm.displayedQuestion,
                  readonly: "",
                  outlined: "",
                  dense: "",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { md: "3" } },
            [
              _c("h3", { staticStyle: { color: "darkred" } }, [
                _vm._v("Response"),
              ]),
              _c("v-select", {
                attrs: {
                  items: _vm.items,
                  filled: "",
                  label: "Select one of the options",
                },
                model: {
                  value: _vm.selectedAgreement,
                  callback: function ($$v) {
                    _vm.selectedAgreement = $$v
                  },
                  expression: "selectedAgreement",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { md: "12" } },
            [
              _c("h3", [_vm._v("Additional Comments")]),
              _c("v-textarea", {
                attrs: { rows: "2", outlined: "", dense: "" },
                model: {
                  value: _vm.displayedJustification,
                  callback: function ($$v) {
                    _vm.displayedJustification = $$v
                  },
                  expression: "displayedJustification",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("h5", [
        _vm._v(
          _vm._s(_vm.page) +
            " of " +
            _vm._s(this.questions.length) +
            " questions"
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }