var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { md: "4" } },
        [
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$emit("fromLinkagesToEvolution")
                },
              },
            },
            [
              _c("v-icon", [_vm._v("mdi-step-backward")]),
              _vm._v(" Evolution "),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-col", { attrs: { md: "4" } }, [
        _c("h3", { staticStyle: { color: "darkred" } }, [
          _vm._v(_vm._s(this.$store.state.currentProgram.name)),
        ]),
      ]),
      _c(
        "v-col",
        { staticClass: "text-right", attrs: { md: "4" } },
        [
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$emit("fromLinkagesToReferences")
                },
              },
            },
            [_vm._v("References "), _c("v-icon", [_vm._v("mdi-step-forward")])],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }