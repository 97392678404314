var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticStyle: { color: "darkred" } }, [
        _vm._v("Evolution report"),
      ]),
      _c(
        "v-row",
        { attrs: { align: "center", justify: "space-around" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "primary",
              on: {
                click: function ($event) {
                  return _vm.generateGraph()
                },
              },
            },
            [_vm._v("Generate evolution graph")]
          ),
        ],
        1
      ),
      _vm.loading
        ? _c("div", [_c("h3", [_vm._v("Generating evolution graph..")])])
        : _vm._e(),
      this.dataLoaded
        ? _c(
            "div",
            [
              _c("h3", { staticStyle: { color: "darkred" } }, [
                _vm._v("Graph"),
              ]),
              _c("v-img", {
                attrs: {
                  "max-height": "1000px",
                  "max-width": "1000px",
                  src: _vm.imageUrl,
                },
              }),
              _c(
                "a",
                { attrs: { href: _vm.pdfUrl, download: "", target: "_blank" } },
                [_vm._v("Download graph")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }