var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { "text-align": "center" } }, [
    _c(
      "h2",
      {
        staticStyle: {
          color: "black",
          "font-size": "xxx-large",
          "text-align": "center",
        },
      },
      [_vm._v("Thank you!")]
    ),
    _c("h3", [
      _vm._v(
        "You have completed " +
          _vm._s(this.$store.state.progress) +
          "% of the questions."
      ),
    ]),
    _c("p"),
    _c(
      "div",
      { staticStyle: { "text-align": "center" } },
      [
        _c(
          "v-btn",
          {
            staticClass: "mx-2",
            attrs: { to: "/EvolutionOverview", color: "primary" },
          },
          [_vm._v("goto evolution report")]
        ),
        _c(
          "v-btn",
          {
            staticClass: "mx-2",
            attrs: { to: "/LinkagesOverview", color: "primary" },
          },
          [_vm._v("goto linkages report")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }