var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", [
        _c("h3", { staticStyle: { color: "darkred" } }, [
          _vm._v("Linkages report"),
        ]),
      ]),
      _c(
        "v-content",
        [
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.generateReport()
                },
              },
            },
            [_vm._v("Download report")]
          ),
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              headers: _vm.headers,
              items: _vm.linkagesValues,
              "items-per-page": 5,
            },
            scopedSlots: _vm._u(
              [
                _vm._l(this.programs, function (program) {
                  return {
                    key: "item." + program.id,
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        item[program.id] === "1"
                          ? _c(
                              "v-icon",
                              {
                                key: item[program.id],
                                attrs: { color: "blue" },
                              },
                              [_vm._v(" mdi-checkbox-marked-circle ")]
                            )
                          : _vm._e(),
                        _vm._t(program.id, null, { item: item }),
                      ]
                    },
                  }
                }),
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }