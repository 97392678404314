var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.report !== undefined
    ? _c(
        "div",
        _vm._l(this.report, function (question, index) {
          return _c(
            "div",
            { key: index },
            [
              _c(
                "v-card",
                { attrs: { elevation: "12", outlined: "" } },
                [
                  _c("v-card-title", { staticStyle: { color: "darkred" } }, [
                    _vm._v(_vm._s(question.name)),
                  ]),
                  _c(
                    "v-card-text",
                    _vm._l(question.questions, function (question, index) {
                      return _c("div", { key: index }, [
                        question.question_answers.summary.Strongly_disagree !==
                        undefined
                          ? _c(
                              "div",
                              [
                                _c("br"),
                                _c(
                                  "h3",
                                  { staticStyle: { color: "darkred" } },
                                  [_vm._v("Question")]
                                ),
                                _c("h3", [_vm._v(_vm._s(question.title))]),
                                _c("br"),
                                _c(
                                  "h3",
                                  { staticStyle: { color: "cornflowerblue" } },
                                  [_vm._v("Overview of all the answers given")]
                                ),
                                _vm._l(
                                  question.question_answers.answers,
                                  function (answer, index) {
                                    return _c("div", { key: index }, [
                                      _c("h5", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getAnswer(
                                                answer.selected_choice
                                              )
                                            )
                                        ),
                                      ]),
                                      _c("h5", [
                                        _vm._v(_vm._s(answer.justify_answer)),
                                      ]),
                                      _c("br"),
                                    ])
                                  }
                                ),
                                _c(
                                  "h3",
                                  { staticStyle: { color: "cornflowerblue" } },
                                  [_vm._v("Summary of all the answers given")]
                                ),
                                _c("h5", [
                                  _vm._v(
                                    "Strongly disagree: " +
                                      _vm._s(
                                        question.question_answers.summary
                                          .Strongly_disagree
                                      )
                                  ),
                                ]),
                                _c("h5", [
                                  _vm._v(
                                    "Disagree: " +
                                      _vm._s(
                                        question.question_answers.summary
                                          .Disagree
                                      )
                                  ),
                                ]),
                                _c("h5", [
                                  _vm._v(
                                    "Disagree nor agree: " +
                                      _vm._s(
                                        question.question_answers.summary
                                          .Neither_agree_nor_disagree
                                      )
                                  ),
                                ]),
                                _c("h5", [
                                  _vm._v(
                                    "Agree: " +
                                      _vm._s(
                                        question.question_answers.summary.Agree
                                      )
                                  ),
                                ]),
                                _c("h5", [
                                  _vm._v(
                                    "Strongly agree: " +
                                      _vm._s(
                                        question.question_answers.summary
                                          .Strongly_agree
                                      )
                                  ),
                                ]),
                              ],
                              2
                            )
                          : _vm._e(),
                      ])
                    }),
                    0
                  ),
                ],
                1
              ),
              _c("br"),
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }