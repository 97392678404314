var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-app-bar",
        { attrs: { dense: "", app: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { md: "7" } },
                [
                  this.$store.state.token !== ""
                    ? _c("v-btn", { attrs: { to: "/", text: "" } }, [
                        _vm._v("Home"),
                      ])
                    : _vm._e(),
                  this.$store.state.token !== ""
                    ? _c(
                        "v-btn",
                        { attrs: { to: "/SelectProgram", text: "" } },
                        [_vm._v("Select programs")]
                      )
                    : _vm._e(),
                  this.$store.state.token !== ""
                    ? _c(
                        "v-btn",
                        { attrs: { to: "/Questionnaire", text: "" } },
                        [_vm._v("Questionnaire")]
                      )
                    : _vm._e(),
                  this.$store.state.token !== ""
                    ? _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { text: "" } },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v("Report")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3336148842
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            [
                              this.$store.state.advisor
                                ? _c(
                                    "v-list-item",
                                    { attrs: { to: "Answers" } },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Answers"),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-list-item",
                                { attrs: { to: "LinkagesOverview" } },
                                [_c("v-list-item-title", [_vm._v("Linkages")])],
                                1
                              ),
                              _c(
                                "v-list-item",
                                { attrs: { to: "EvolutionOverview" } },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Evolution"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  this.$store.state.token !== ""
                    ? _c("v-btn", { attrs: { to: "/EndPage", text: "" } }, [
                        _vm._v("Finalize Questionnaire"),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "3" } },
                [
                  this.$store.state.selectedAgency.name !== undefined &&
                  this.$store.state.token !== ""
                    ? _c("v-btn", { attrs: { text: "" } }, [
                        _vm._v(
                          "Agency: " +
                            _vm._s(this.$store.state.selectedAgency.name) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "2" } },
                [
                  this.$store.state.advisor && this.$store.state.token !== ""
                    ? _c("v-btn", { attrs: { text: "" } }, [_vm._v("advisor")])
                    : _vm._e(),
                  this.$store.state.token !== ""
                    ? _c(
                        "v-btn",
                        { attrs: { text: "" }, on: { click: _vm.logout } },
                        [
                          _c("v-icon", [_vm._v("mdi-logout")]),
                          _vm._v(" Logout "),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-main", [_c("router-view")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }