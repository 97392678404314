var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("br"),
      _c("h3", [_vm._v(_vm._s(_vm.question))]),
      _c("br"),
      _c(
        "v-dialog",
        {
          attrs: { width: "800" },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5 grey lighten-2" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _c("v-card-text", [_vm._v(_vm._s(_vm.description))]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.showDialog = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._l(this.$store.state.areas, function (area) {
        return _c(
          "v-card",
          {
            key: area.id,
            attrs: { elevation: 2, color: "blue-grey lighten-4" },
          },
          [
            _c(
              "v-container",
              { staticClass: "fill-height", attrs: { fluid: "" } },
              _vm._l(area.groups, function (group, index) {
                return _c(
                  "v-row",
                  { key: group.id, attrs: { "no-gutters": "" } },
                  [
                    _c("v-col", { attrs: { md: "2" } }, [
                      index === 0
                        ? _c("h3", [_vm._v(_vm._s(area.name))])
                        : _vm._e(),
                    ]),
                    _c(
                      "v-col",
                      { attrs: { md: "4" } },
                      [
                        _c(
                          "v-list-item",
                          { attrs: { dense: "" } },
                          [
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(group.name)),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { md: "6" } },
                      [
                        _c(
                          "v-list-item-group",
                          _vm._l(group.programs, function (program) {
                            return _c(
                              "v-list-item",
                              { key: program.id, attrs: { dense: "" } },
                              [
                                _c(
                                  "v-list-item-action",
                                  [
                                    _c("v-checkbox", {
                                      attrs: {
                                        "input-value": _vm.isSelected(
                                          program.id
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectProgram(program.id)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      color: "black",
                                      "x-small": "",
                                      text: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showProgramDescription(
                                          program
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(program.name) + " ")]
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }