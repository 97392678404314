var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c("v-col", { attrs: { md: "3" } }),
      _c(
        "v-col",
        { attrs: { md: "6" } },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "justify-center" }, [
                _vm._v("Please login with your username and password"),
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    { staticStyle: { margin: "1px" } },
                    [
                      _c("v-col", { attrs: { md: "3" } }),
                      _c(
                        "v-col",
                        { attrs: { md: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "prepend-icon": "mdi-account",
                              name: "user",
                            },
                            model: {
                              value: _vm.username,
                              callback: function ($$v) {
                                _vm.username = $$v
                              },
                              expression: "username",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticStyle: { margin: "1px" } },
                    [
                      _c("v-col", { attrs: { md: "3" } }),
                      _c(
                        "v-col",
                        { attrs: { md: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "prepend-icon": "mdi-lock-outline",
                              type: "password",
                              name: "password",
                            },
                            model: {
                              value: _vm.password,
                              callback: function ($$v) {
                                _vm.password = $$v
                              },
                              expression: "password",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.error
                    ? _c(
                        "v-row",
                        { staticStyle: { margin: "1px", color: "darkred" } },
                        [
                          _c("v-col", { attrs: { md: "3" } }),
                          _c(
                            "v-col",
                            { staticClass: "text-right", attrs: { md: "6" } },
                            [
                              _c("h3", [
                                _vm._v(
                                  "Invalid username or password, please try again!"
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    { staticStyle: { margin: "1px" } },
                    [
                      _c("v-col", { attrs: { md: "6" } }),
                      _c(
                        "v-col",
                        { staticClass: "text-right", attrs: { md: "3" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-2",
                              attrs: { right: "", dark: "", color: "primary" },
                              on: { click: _vm.login },
                            },
                            [
                              _vm._v(" login "),
                              _c("v-icon", { attrs: { right: "" } }, [
                                _vm._v("mdi-login"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }