var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-textarea", {
        attrs: { rows: "20", readonly: "", dense: "" },
        model: {
          value: _vm.description,
          callback: function ($$v) {
            _vm.description = $$v
          },
          expression: "description",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }