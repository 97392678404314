import { render, staticRenderFns } from "./ProgramDescription.vue?vue&type=template&id=13936908&"
import script from "./ProgramDescription.vue?vue&type=script&lang=js&"
export * from "./ProgramDescription.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/opt/epictool-site/EPIC-Tool-master/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('13936908')) {
      api.createRecord('13936908', component.options)
    } else {
      api.reload('13936908', component.options)
    }
    module.hot.accept("./ProgramDescription.vue?vue&type=template&id=13936908&", function () {
      api.rerender('13936908', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ProgramDescription.vue"
export default component.exports