var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    this.$store.state.programSelection.size === 0
      ? _c("h3", [
          _vm._v("Please select 1 or more programs in the select programs tab"),
        ])
      : _vm._e(),
    this.$store.state.programSelection.size > 0
      ? _c(
          "div",
          [
            _c(
              "v-expansion-panels",
              {
                staticClass: "mb-6",
                attrs: { multiple: "" },
                model: {
                  value: _vm.expand,
                  callback: function ($$v) {
                    _vm.expand = $$v
                  },
                  expression: "expand",
                },
              },
              [
                _c(
                  "v-expansion-panel",
                  { attrs: { value: _vm.expand } },
                  [
                    _c(
                      "v-expansion-panel-header",
                      { attrs: { "expand-icon": "mdi-menu-down" } },
                      [
                        _c("p", [
                          _vm._v(
                            "Estimated remaining time " +
                              _vm._s(this.$store.state.remainingTime) +
                              " minutes (" +
                              _vm._s(this.$store.state.progress) +
                              "%)"
                          ),
                        ]),
                        _c("br"),
                      ]
                    ),
                    _c(
                      "v-expansion-panel-content",
                      [
                        _c("v-progress-linear", {
                          attrs: { value: this.$store.state.progress },
                        }),
                        _c(
                          "v-tabs",
                          {
                            model: {
                              value: _vm.selectedAreaIndex,
                              callback: function ($$v) {
                                _vm.selectedAreaIndex = $$v
                              },
                              expression: "selectedAreaIndex",
                            },
                          },
                          _vm._l(this.$store.state.areas, function (area) {
                            return _c(
                              "v-tab",
                              {
                                key: area.id,
                                attrs: { disabled: _vm.disableArea(area.id) },
                                on: {
                                  change: function ($event) {
                                    return _vm.updateVisibleProgramsAfterAreaChange(
                                      area.id
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(" " + _vm._s(area.name) + " "),
                                _vm.isAreaCompleted(area.id)
                                  ? _c("v-icon", { attrs: { right: "" } }, [
                                      _vm._v("mdi-checkbox-marked-circle"),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                        _c(
                          "v-tabs",
                          {
                            model: {
                              value: _vm.selectedGroupIndex,
                              callback: function ($$v) {
                                _vm.selectedGroupIndex = $$v
                              },
                              expression: "selectedGroupIndex",
                            },
                          },
                          _vm._l(this.visibleGroups, function (group) {
                            return _c(
                              "v-tab",
                              {
                                key: group.id,
                                attrs: { disabled: _vm.disableEvents },
                                on: {
                                  change: function ($event) {
                                    return _vm.updateVisibleProgramsAfterGroupChange(
                                      group.id
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(" " + _vm._s(group.name) + " "),
                                _vm.isGroupCompleted(group.id)
                                  ? _c("v-icon", { attrs: { right: "" } }, [
                                      _vm._v("mdi-checkbox-marked-circle"),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                        _c(
                          "v-tabs",
                          {
                            model: {
                              value: _vm.selectedProgramIndex,
                              callback: function ($$v) {
                                _vm.selectedProgramIndex = $$v
                              },
                              expression: "selectedProgramIndex",
                            },
                          },
                          _vm._l(this.visiblePrograms, function (program) {
                            return _c(
                              "v-tab",
                              {
                                key: program.id,
                                attrs: { disabled: _vm.disableEvents },
                                on: {
                                  change: function ($event) {
                                    return _vm.updateSelectedProgram(program)
                                  },
                                },
                              },
                              [
                                _vm._v(" " + _vm._s(program.name) + " "),
                                _vm.isProgramCompleted(program.id)
                                  ? _c("v-icon", { attrs: { right: "" } }, [
                                      _vm._v("mdi-checkbox-marked-circle"),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("br"),
            _c(
              "v-stepper",
              {
                model: {
                  value: _vm.e1,
                  callback: function ($$v) {
                    _vm.e1 = $$v
                  },
                  expression: "e1",
                },
              },
              [
                _c(
                  "v-stepper-header",
                  [
                    _c(
                      "v-stepper-step",
                      { attrs: { complete: _vm.e1 > 1, step: "1" } },
                      [_vm._v("Program description")]
                    ),
                    _c("v-divider"),
                    _c(
                      "v-stepper-step",
                      { attrs: { complete: _vm.e1 > 2, step: "2" } },
                      [_vm._v("National framework")]
                    ),
                    _c("v-divider"),
                    _c(
                      "v-stepper-step",
                      { attrs: { complete: _vm.e1 > 3, step: "3" } },
                      [_vm._v("Key agency actions")]
                    ),
                    _c("v-divider"),
                    _c(
                      "v-stepper-step",
                      { attrs: { complete: _vm.e1 > 4, step: "4" } },
                      [_vm._v("Evolution")]
                    ),
                    _c("v-divider"),
                    _c(
                      "v-stepper-step",
                      { attrs: { complete: _vm.e1 > 5, step: "5" } },
                      [_vm._v("Linkages")]
                    ),
                    _c("v-divider"),
                    _c(
                      "v-stepper-step",
                      { attrs: { complete: _vm.e1 > 6, step: "6" } },
                      [_vm._v("References")]
                    ),
                  ],
                  1
                ),
                _c(
                  "v-stepper-items",
                  [
                    _c(
                      "v-stepper-content",
                      { attrs: { step: "1" } },
                      [
                        _c("program-description-navigation", {
                          on: {
                            fromProgramDescriptionToNationalFramework:
                              _vm.fromProgramDescriptionToNationalFramework,
                          },
                        }),
                        _c("program-description", {
                          ref: "programDescription",
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-stepper-content",
                      { attrs: { step: "2" } },
                      [
                        _c("national-frameworks", {
                          ref: "nationalFramework",
                          on: {
                            updateProgress: _vm.updateProgress,
                            fromNationalFrameworkToProgramDescription:
                              _vm.fromNationalFrameworkToProgramDescription,
                            fromNationalFrameworkToKeyAgency:
                              _vm.fromNationalFrameworkToKeyAgency,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-stepper-content",
                      { attrs: { step: "3" } },
                      [
                        _c("key-agency-actions", {
                          ref: "keyAgency",
                          on: {
                            updateProgress: _vm.updateProgress,
                            fromKeyAgencyToNationalFramework:
                              _vm.fromKeyAgencyToNationalFramework,
                            fromKeyAgencyToEvolution:
                              _vm.fromKeyAgencyToEvolution,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-stepper-content",
                      { attrs: { step: "4" } },
                      [
                        _c("evolution-navigation", {
                          on: {
                            fromEvolutionKeyAgency: _vm.fromEvolutionKeyAgency,
                            fromEvolutionToLinkages:
                              _vm.fromEvolutionToLinkages,
                          },
                        }),
                        _c("evolution", {
                          ref: "evolution",
                          on: { updateProgress: _vm.updateProgress },
                        }),
                        _c("evolution-navigation", {
                          on: {
                            fromEvolutionKeyAgency: _vm.fromEvolutionKeyAgency,
                            fromEvolutionToLinkages:
                              _vm.fromEvolutionToLinkages,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-stepper-content",
                      { attrs: { step: "5" } },
                      [
                        _c("linkages-navigation", {
                          on: {
                            fromLinkagesToEvolution:
                              _vm.fromLinkagesToEvolution,
                            fromLinkagesToReferences:
                              _vm.fromLinkagesToReferences,
                          },
                        }),
                        _c("linkages", {
                          ref: "linkages",
                          on: { updateProgress: _vm.updateProgress },
                        }),
                        _c("linkages-navigation", {
                          on: {
                            fromLinkagesToEvolution:
                              _vm.fromLinkagesToEvolution,
                            fromLinkagesToReferences:
                              _vm.fromLinkagesToReferences,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-stepper-content",
                      { attrs: { step: "6" } },
                      [
                        _c(
                          "references-navigation",
                          {
                            on: {
                              back: function ($event) {
                                _vm.e1 = 5
                              },
                              forward: _vm.gotoNextProgram,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.nextProgram !== null
                                    ? _vm.nextProgram.name
                                    : "finalize questionnaire"
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c("references", { ref: "references" }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }