import { render, staticRenderFns } from "./Answers.vue?vue&type=template&id=7c79ccab&"
import script from "./Answers.vue?vue&type=script&lang=js&"
export * from "./Answers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})


/* hot reload */
if (module.hot) {
  var api = require("/opt/epictool-site/EPIC-Tool-master/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7c79ccab')) {
      api.createRecord('7c79ccab', component.options)
    } else {
      api.reload('7c79ccab', component.options)
    }
    module.hot.accept("./Answers.vue?vue&type=template&id=7c79ccab&", function () {
      api.rerender('7c79ccab', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Answers.vue"
export default component.exports