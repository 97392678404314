var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(
        this.$store.state.currentProgram.references,
        function (reference, index) {
          return _c(
            "div",
            { key: index },
            [
              _c("v-textarea", {
                attrs: { rows: "3", dense: "", readonly: "" },
                model: {
                  value: reference.description,
                  callback: function ($$v) {
                    _vm.$set(reference, "description", $$v)
                  },
                  expression: "reference.description",
                },
              }),
              _c("a", { attrs: { href: reference.link, target: "_blank" } }, [
                _vm._v(_vm._s(reference.link)),
              ]),
            ],
            1
          )
        }
      ),
      _c("br"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }