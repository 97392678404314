// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/back.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.background[data-v-1c0d9c08] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  height: 95vh;\n  width: 100vw;\n}\ntable[data-v-1c0d9c08], th[data-v-1c0d9c08], td[data-v-1c0d9c08] {\n  border: 1px solid black;\n  padding: 3px;\n}\n.cards[data-v-1c0d9c08] {\n  color: darkred;\n  background-color: white;\n  text-align: center;\n  opacity: 80%;\n}\n", ""]);
// Exports
module.exports = exports;
